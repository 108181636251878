//
//
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.
$primary: #33BE48;
$success: #0C6D1B;
$secondary: #0C6D1B;
$dark: #242933;
$danger: rgb(255, 73, 73);
