//
//
// user.scss
//
// Place your own theme CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

body {
  color: #5A5E65;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  color: var(--bs-dark);
}

.link {
  text-decoration: underline;
}

.btn {
  border-radius: 16px;
  font-size: 1.0rem;
  padding: 0.6rem 1.4rem;
  box-shadow: 0px 0px 14px rgba(26, 96, 37, 0.24);
  -webkit-box-shadow: 0px 0px 14px rgba(26, 96, 37, 0.24);
}

.btn:hover, .btn:active, .btn:focus {
  text-decoration: none;
}

.btn:hover {
  box-shadow: 0px 0px 14px rgba(26, 96, 37, 0.24);
  -webkit-box-shadow: 0px 0px 14px rgba(26, 96, 37, 0.24);
}

.btn:focus, .btn:active, .btn:focus, .btn:active, .btn:active:focus,
.btn.active:focus, .show > .btn.dropdown-toggle {
  box-shadow: 0px 0px 16px rgba(26, 96, 37, 0.44);
  -webkit-box-shadow: 0px 0px 16px rgba(26, 96, 37, 0.44);
}

.btn:disabled, .btn.disabled {
  opacity: 0.5;
}

.btn-sm, .btn-group-sm > .btn, .btn-group-sm > .btn {
  padding: 0.6rem 1.4rem;
}

.btn-lg, .btn-group-lg > .btn, .btn-group-lg > .btn {
  padding: 0.8rem 1.4rem;
}

.btn-xl, .btn-group-xl > .btn {
  padding: 1.0rem 1.4rem;
  font-size: 1.2rem;
}

.btn-primary {
  color: #fff;
  background-color: var(--bs-primary);
  box-shadow: 0px 0px 14px rgba(26, 96, 37, 0.24);
  -webkit-box-shadow: 0px 0px 14px rgba(26, 96, 37, 0.24);
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #2AB13F;
}

.btn-primary:focus, .btn-primary:active, .btn-primary:active:focus,
.btn-primary.active:focus, .show > .btn-primary.dropdown-toggle {
  box-shadow: 0px 0px 16px rgba(26, 96, 37, 0.44);
  -webkit-box-shadow: 0px 0px 16px rgba(26, 96, 37, 0.44);
}

.btn-secondary, .btn-outline-primary {
  color: var(--bs-primary);
  background-color: #fff;
  border: 1px solid var(--bs-primary);
  box-shadow: 0px 0px 14px rgba(26, 96, 37, 0.14);
  -webkit-box-shadow: 0px 0px 14px rgba(26, 96, 37, 0.14);
}

.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active, .show > .btn-secondary.dropdown-toggle,
.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active, .show > .btn-outline-primary.dropdown-toggle {
  color: #2AB13F;
  background-color: #fff;
  border: 1px solid #2AB13F;
}

.btn-secondary:focus, .btn-secondary:active, .btn-secondary:active:focus,
.btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle,
.btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary:active:focus,
.btn-outline-primary.active:focus, .show > .btn-outline-primary.dropdown-toggle {
  box-shadow: 0px 0px 16px rgba(26, 96, 37, 0.28);
  -webkit-box-shadow: 0px 0px 16px rgba(26, 96, 37, 0.28);
}

// Form styling
.form-label {
  display: flex;
  color: var(--bs-dark);
  margin-bottom: 0.4rem;
}

.form-control, .form-select {
  border-radius: 16px;
  border-width: 1px;
  border-color: rgba(196, 196, 196, 1);
  background-color: var(--bs-white);
  padding: 0.75rem;
  line-height: 18.4px;
  color: #000;
}

.form-control:focus, .form-select:focus, .form-control:active, .form-select:active {
  color: var(--bs-dark);
  background-color: var(--bs-white);
  border-color: var(--bs-primary);
  border-width: 2px;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(57, 196, 78, 0.2);
  box-shadow: 0 0 0 0.25rem rgba(57, 196, 78, 0.2);
}

.form-control::-webkit-input-placeholder, .form-control::-moz-placeholder, .form-control:-ms-input-placeholder,
.form-control::-ms-input-placeholder, .form-control::placeholder {
  color: #7F7F7F !important;
  opacity: 1;
}

.nav-link {
  color: var(--bs-secondary);
}

.nav-tabs .nav-link {
  border: 1px solid var(--bs-secondary);
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #FFFFFF;
  background: var(--bs-secondary);
  border-color: transparent;
}

.invalid-feedback {
  font-size: 14px;
}

.card.service-card:before {
  background-color: var(--bs-primary);
}

.card {
  background: #F9FBFD;
  border: 1px solid #D9D9D9;
  border-radius: 12px;
}